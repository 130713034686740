<div class="page">
    <div class="page__title">Settings</div>
    <p-divider />
    <div class="page__content">
        <div class="section">
            <div class="section__title">Notifications</div>
            <form [formGroup]="notificationSettingsGroup">
                <div class="flex justify-content-between align-items-center pt-3 pb-3 border-round">
                    <div class="flex">
                        <i class="pi pi-envelope text-lg mt-1 mr-3 align-self-start text-gray-500"></i>
                        <div class="flex flex-column">
                            <span class="font-bold text-lg">Mail notifications</span>
                            <span class="text-500 mt-2">Controls whether email notifications are enabled for the
                                system.</span>
                        </div>
                    </div>
                    <div class="ml-auto">
                        <p-inputSwitch formControlName="isEmailEnabled" />
                    </div>
                </div>
                <p-divider />
                <div class="flex justify-content-between align-items-center pt-3 pb-3 border-round">
                    <div class="flex">
                        <i class="pi pi-mobile text-lg mt-1 mr-3 align-self-start text-gray-500"></i>
                        <div class="flex flex-column">
                            <span class="font-bold text-lg">SMS notifications</span>
                            <span class="text-500 mt-2">Controls whether SMS notifications are enabled for the
                                system.</span>
                        </div>
                    </div>
                    <div class="ml-auto">
                        <p-inputSwitch formControlName="isSmsEnabled" />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="page__actions">
        <p-button label="Save" (click)="save()" />
    </div>
</div>