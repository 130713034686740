import { Environment } from '../app/core/models/environment';

export const environment: Environment = {
    environmentName: 'development',
    production: false,
    azureAd: {
        clientId: 'e1487aeb-1e92-4aa6-87f0-005e3bb3930d',
        tenantId: '8c1b69f3-607d-49aa-91c5-47073e72c768',
        scopeUrls: [
            'api://f4a1c8e3-f8c9-45e8-b24d-8291d51741c4/CareHub.Read.All',
            'api://f4a1c8e3-f8c9-45e8-b24d-8291d51741c4/CareHub.Write.All',
        ]
    },
    apiUrl: 'https://app-carehub-dev-001.azurewebsites.net'
};
