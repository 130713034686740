<!-- TODO Coordinate with backend on how to filter list with service requests -->

<p-table [value]="serviceRequests" styleClass="p-datatable-striped">
  <ng-template pTemplate="header">
    <tr>
      <th>Title</th>
      <th>Status</th>
      <th>Created by</th>
      <th>Assigned to</th>
      <th>Start/Join teams chat</th>
      <th>Priority</th>
      <th>dueDate</th>
      <th>Compliance</th>
      <th>Created date</th>
      <th>Action</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-serviceRequest>
    <tr>
      <td>{{ serviceRequest.title }}</td>
      <td>{{ serviceRequest.status }}</td>
      <td>{{ serviceRequest.createdBy }}</td>
      <td>{{ serviceRequest.assignedTo }}</td>
      <td>
        <p-button
          type="button"
          label="Start/Join teams chat"
          severity="secondary"
          (click)="startTeamsChat(serviceRequest)"></p-button>
      </td>
      <td>{{ serviceRequest.priority }}</td>
      <td>{{ serviceRequest.dueDate }}</td>
      <td>{{ serviceRequest.compliance }}</td>
      <td>{{ serviceRequest.createdDate }}</td>
      <td>
        <p-button
          type="button"
          label="Edit"
          severity="primary"
          (click)="editServiceRequest(serviceRequest)"></p-button>
      </td>
    </tr>
  </ng-template>
</p-table>

<carehub-service-request-update
  *ngIf="selectedServiceRequest"
  [visible]="showEditServiceRequest()"
  (visibleChange)="updateShowEditServiceRequest($event)"
  [serviceRequest]="selectedServiceRequest"
  (serviceRequestChange)="
    selectedServiceRequest()
  "></carehub-service-request-update>
