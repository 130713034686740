import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  WritableSignal,
} from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule,
} from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EditorModule } from 'primeng/editor';
import { InputItem } from '../../../core/models/input-item';

type InputTypes =
  | string
  | number
  | boolean
  | InputItem[]
  | InputItem
  | Date
  | null;

@Component({
  selector: 'carehub-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TooltipModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    InputNumberModule,
    RadioButtonModule,
    AutoCompleteModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    EditorModule,
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent implements OnInit {
  /** Required values */
  @Input() value!: InputTypes;
  @Output() valueChange: EventEmitter<InputTypes> =
    new EventEmitter<InputTypes>();

  @Input() inputType!:
    | 'text'
    | 'number'
    | 'textarea'
    | 'switch'
    | 'radio'
    | 'autocomplete'
    | 'select'
    | 'multiselect'
    | 'calendar'
    | 'wysiwyg';

  @Input() id!: string;
  /** Required values end */

  /** Optional values */
  @Input() label?: string;
  @Input() tooltip?: string;
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Input() helpText?: string;
  /** Optional values end */

  /** Optional settings for textarea */
  @Input() rows?: number = 5;
  @Input() cols?: number = 30;
  /** Optional settings for textarea end */

  /** Optional settings for radio buttons, multiselect, select & autocomplete */
  @Input() items?: InputItem[] = [];
  /** Optional settings for radio buttons, multiselect, select & autocomplete end */

  /** Output for autocomplete */
  @Output() searchEvent: EventEmitter<string> = new EventEmitter<string>();
  /** Output for autocomplete end */

  explanation!: WritableSignal<string>;

  ngOnInit(): void {
    if (this.disabled && !this.tooltip) {
      this.tooltip = 'This field is disabled';
    }
  }

  /**
   * Emit the value of the input field/selected item
   */
  emitValue(): void {
    this.valueChange.emit(this.value);

    if (this.inputType === 'select') {
      const value: InputItem = this.value as InputItem;
      if (value.explanation) {
        this.explanation.set(value.explanation);
      }
    }
  }

  /**
   * Emit the value of the input field to use for search in parent component
   * @param event AutoCompleteCompleteEvent the PRIME NG event that is triggered when the user types in the autocomplete field
   */
  search(event: AutoCompleteCompleteEvent): void {
    this.searchEvent.emit(event.query);
  }
}
