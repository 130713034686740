import { ResolveFn } from '@angular/router';
import { inject } from '@angular/core';
import { ServiceRequestService } from './service-request.service';
import { ServiceRequest } from './service-request';

export const serviceRequestsResolver: ResolveFn<
  ServiceRequest[]
> = async () => {
  const _serviceRequestService: ServiceRequestService = inject(
    ServiceRequestService
  );

  return await _serviceRequestService.get();
};
