import {
  Component,
  EventEmitter,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { FileSelectEvent, FileUploadModule } from 'primeng/fileupload';
import { Router, RouterLink } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { StepperModule } from 'primeng/stepper';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ServiceRequestService } from '../../service-request.service';
import { CommonModule } from '@angular/common';
import { InputItem } from '../../../../core/models/input-item';
import { ServiceRequest } from '../../service-request';
import { InputComponent } from '../../../../core/utilities/input/input.component';

@Component({
  selector: 'carehub-service-request-creation',
  standalone: true,
  imports: [
    InputComponent,
    FileUploadModule,
    StepperModule,
    ConfirmDialogModule,
    RouterLink,
    CommonModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './service-request-creation.component.html',
  styleUrl: './service-request-creation.component.scss',
})
export class ServiceRequestCreationComponent {
  @Output() closeEvent = new EventEmitter<void>();

  serviceRequest: WritableSignal<ServiceRequest> = signal({
    id: null,
    title: '',
    description: '',
    status: 'New',
    requestType: null,
    priority: null,
    critical: false,
    severity: null,
    urgency: null,
    dueDate: null,
    compliance: false,
    confidential: false,
    requester: '',
    createdDate: null,
    assignedTo: null,
    chatId: '',
  });

  confirmedWithColleagues: boolean = false;

  types: InputItem[] = [
    { name: 'Hardware', value: 'hardware' },
    { name: 'Software', value: 'software' },
  ];

  severityOptions: InputItem[] = [
    {
      name: 'Low',
      value: 4,
      explanation: 'Some local users are affected; A workaround is available.',
    },
    {
      name: 'Medium',
      value: 3,
      explanation:
        '25% of vital operations are stopped or extremely slow. A workaround is available.',
    },
    {
      name: 'High',
      value: 2,
      explanation:
        '25-50% of vital operations are stopped or extremely slow. No workaround.',
    },
    {
      name: 'Critical',
      value: 1,
      explanation:
        'Vital business operations stopped for all or most users. No workaround.',
    },
  ];

  urgencyOptions: InputItem[] = [
    { name: 'Low', value: 4, explanation: 'Work is only affected slightly.' },
    { name: 'Medium', value: 3, explanation: 'Work is reduced.' },
    {
      name: 'High',
      value: 2,
      explanation: 'Work has significantly been reduced.',
    },
    { name: 'Critical', value: 1, explanation: 'Work has stopped.' },
  ];

  constructor(
    private _router: Router,
    private _confirmationService: ConfirmationService,
    private _serviceRequestService: ServiceRequestService
  ) {}

  /**
   * Generic function to update a field in the servicerequest
   *
   * @param field
   * @param value
   */
  updateField<Key extends keyof ServiceRequest>(
    field: Key,
    value: ServiceRequest[Key]
  ): void {
    this.serviceRequest.set({
      ...this.serviceRequest(),
      [field]: value,
    });
  }

  /**
   * Create a new servicerequest
   */
  async createServiceRequest(): Promise<void> {
    let description: string = this.serviceRequest().description;
    description += '<br><br> ---------- <br><br>';

    const requestType: InputItem | null = this.serviceRequest().requestType;
    if (requestType) {
      description += 'Type: ' + requestType.value + '<br>';
    }

    description +=
      'ServiceRequest was created on the following URL: ' + this._router.url;

    this.updateField('description', description);

    // Set the priority of the service request
    this.updateField('priority', Math.round(this.serviceRequestPriority()));

    await this._serviceRequestService.create(this.serviceRequest());

    this.closeEvent.emit();
  }

  /**
   * Confirm that the servicerequest is critical
   */
  confirmCriticalServiceRequest(): void {
    if (this.serviceRequest().critical) {
      this._confirmationService.confirm({
        header: 'Are you sure?',
        icon: 'pi pi-exclamation-triangle',
        acceptButtonStyleClass: 'p-button-danger',
        accept: () => {
          this.updateField('critical', true);
        },
        reject: () => {
          this.updateField('critical', false);
        },
      });
    }
  }

  /**
   * Close the parent dialog
   */
  closeDialog(): void {
    this.closeEvent.emit();
  }

  /**
   * Clear the file input field
   */
  onClear(): void {
    this.updateField('files', []);
  }

  /**
   * Add selected files to the servicerequest
   *
   * @param event FileSelectEvent
   */
  onSelect(event: FileSelectEvent): void {
    this.updateField('files', event.currentFiles);
  }

  /**
   * Get the priority of the servicerequest
   *
   * @returns number The calculated priority
   */
  serviceRequestPriority(): number {
    return this._serviceRequestService.calculatePriority(this.serviceRequest());
  }

  /**
   * Update the servicerequest urgency value
   *
   * @param event
   */
  updateServiceRequestUrgency(event: unknown): void {
    this.updateField('urgency', (event as InputItem).value as number);
  }

  /**
   * Update the servicerequest severity value
   *
   * @param event
   */
  updateServiceRequestSeverity(event: unknown): void {
    this.updateField('severity', (event as InputItem).value as number);
  }
}
