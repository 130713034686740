<p-dialog
  header="Update service request"
  [modal]="true"
  [(visible)]="visible"
  closeOnEscape="false"
  dismissableMask
  (onHide)="close()"
  styleClass="service-request-dialog">
  <div class="flex align-items-center">
    <carehub-input
      inputType="text"
      id="title"
      label="Title"
      [(value)]="serviceRequest().title"
      [disabled]="true"
      class="col-10"
      helpText="We need a good descriptive title for the service request, as this will help us quickly to assess and assign the correct person"></carehub-input>
    <p-button
      label="Close service request"
      severity="warning"
      (click)="confirmCloseServiceRequest()"
      class="col-2" />
  </div>

  <div class="flex">
    <carehub-input
      inputType="wysiwyg"
      id="description"
      label="Description"
      [(value)]="serviceRequest().description"
      [rows]="12"
      class="col-12"
      helpText="The better you can describe the issue you are having, the better we can help you"></carehub-input>
  </div>
  <div class="flex">
    <carehub-input
      inputType="calendar"
      id="duedate"
      label="Due date"
      [(value)]="serviceRequest().dueDate"
      class="col-4"
      placeholder="mm/dd/yyyy"
      helpText="Does the service request need to be fixed by a certain date?"></carehub-input>
    <carehub-input
      inputType="switch"
      id="compliance"
      label="Compliance"
      [(value)]="serviceRequest().compliance"
      class="col-2"
      helpText="Does the service request subject and issue have influence on the compliance?"></carehub-input>
    <carehub-input
      inputType="switch"
      id="confidential"
      label="Confidential"
      [(value)]="serviceRequest().confidential"
      class="col-2"
      helpText="Is the service request regarding a subject that should be handled confidentially?"></carehub-input>
    <carehub-input
      inputType="select"
      id="severity"
      label="Severity"
      [(value)]="serviceRequest().severity"
      [items]="options"
      class="col-2"
      placeholder="Severity"
      helpText="What severity does the service request have?"></carehub-input>
    <carehub-input
      inputType="select"
      id="urgency"
      label="Urgency"
      [(value)]="serviceRequest().urgency"
      [items]="options"
      class="col-2"
      placeholder="Urgency"
      helpText="Is the service request urgent?"></carehub-input>
  </div>

  <div class="flex justify-content-end mt-5 mb-5">
    <p-button label="Update" (click)="update()" />
  </div>

  <p-confirmDialog>
    <ng-template pTemplate="message" let-message>
      <div
        class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border p-2">
        <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
        <p>
          Please confirm that you want to close the service request, and please
          let us know why.
        </p>
        <carehub-input
          inputType="text"
          helpText="Let us know why you are closing the service request"
          placeholder="Reason to close the service request"
          [(value)]="closeReason"
          class="col-12"></carehub-input>
      </div>
    </ng-template>
  </p-confirmDialog>
</p-dialog>
