import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { SidebarModule } from 'primeng/sidebar';
import { Subscription } from 'rxjs';

import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'carehub-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MenuModule,
    SidebarModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit, OnDestroy {
  opened: boolean = false;
  menuSubscription!: Subscription;

  menuItems: MenuItem[] | undefined = [
    {
      label: 'Home',
      items: [
        {
          label: 'Home',
          icon: 'pi pi-fw pi-home',
          routerLink: '/',
        },
      ],
    },
    {
      label: 'Utilities',
      items: [
        {
          label: 'Service Requests',
          routerLink: '/utilities/service-requests',
        },
      ],
    },
    {
      label: 'Profile',
      items: [
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          command: (): void => {
            this._router.navigate(['/settings']);
          }
        }
      ]
    }
  ];

  constructor(
    private _menuService: MenuService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.menuSubscription = this._router.events.subscribe(() => {
      // If menu is open, close it when navigating to a new route
      if (this.toggleState === true) {
        this._menuService.toggleMenu();
      }
    });
  }

  ngOnDestroy(): void {
    this.menuSubscription.unsubscribe();
  }

  /**
   * Get the current state of the sidebar menu
   */
  get toggleState(): boolean {
    return this._menuService.getShowMenuState();
  }

  /**
   * Toggle the sidebar menu
   */
  toggleMenu(): void {
    this._menuService.toggleMenu();
  }
}
