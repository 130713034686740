import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ServiceRequestService } from '../../service-request.service';
import { ServiceRequest } from '../../service-request';
import { ServiceRequestUpdateComponent } from '../service-request-update/service-request-update.component';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'carehub-servicerequests',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ServiceRequestUpdateComponent,
  ],
  templateUrl: './service-requests.component.html',
  styleUrl: './service-requests.component.scss',
})
export class ServiceRequestsComponent implements OnInit {
  serviceRequests: ServiceRequest[] = [];
  showEditServiceRequest: WritableSignal<boolean> = signal(false);
  selectedServiceRequest!: WritableSignal<ServiceRequest>;

  constructor(
    private route: ActivatedRoute,
    private _serviceRequestService: ServiceRequestService,
    private _messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.serviceRequests = this.route.snapshot.data['serviceRequests'];
  }

  /**
   * Start a teams chat regarding the service request
   *
   * @param servicerequest ServiceRequest
   */
  startTeamsChat(serviceRequest: ServiceRequest): void {
    // If the service request is not assigned to anyone, show an warning message.
    if (serviceRequest.assignedTo === null) {
      this._messageService.add({
        severity: 'warning',
        detail:
          'The ticket has not been assigned to anyone yet, so you can not chat with someone about this ticket.',
      });
      return;
    }
    this._serviceRequestService.joinTeamsChat(serviceRequest);
  }

  /**
   * Open the update service request dialog
   *
   * @param serviceRequest ServiceRequest
   */
  editServiceRequest(serviceRequest: ServiceRequest): void {
    this.selectedServiceRequest = signal(serviceRequest);
    this.showEditServiceRequest.set(true);
  }

  /**
   * Update the showEditServiceRequest signal
   *
   * @param showEditServiceRequest boolean
   */
  updateShowEditServiceRequest(showEditServiceRequest: boolean): void {
    this.showEditServiceRequest.set(showEditServiceRequest);
  }
}
