import { CommonModule } from '@angular/common';
import { Component, computed, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ProgressBarModule } from 'primeng/progressbar';

import { AuthService } from '../../auth/auth.service';
import { Loaderbar } from '../../models/loaderbar';
import { Notification } from '../../models/notification';
import { LoaderService } from '../../services/loader.service';
import { MenuService } from '../../services/menu.service';
import { NotificationService } from '../../services/notification.service';
import { SignalRService } from '../../services/signalr.service';

@Component({
  selector: 'carehub-header',
  standalone: true,
  imports: [
    FormsModule,
    BadgeModule,
    ButtonModule,
    CommonModule,
    MenubarModule,
    MenuModule,
    ProgressBarModule,
    AvatarModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  notifications = signal<Notification[]>([]);

  notificationMenuItems = computed<MenuItem[]>(() =>
    this.notifications().map(notification => ({
      id: notification.id.toString(),
      label: notification.message,
      url: notification.link,
      styleClass: notification.isRead ? '' : 'menu-item__link--unread',
    })));

  unreadNotificationsCount = computed(() =>
    this.notifications().filter(notification =>
      !notification.isRead).length
  );

  constructor(
    private _loaderService: LoaderService,
    private _menuService: MenuService,
    private _authService: AuthService,
    private _signalRService: SignalRService,
    private _notificationService: NotificationService
  ) { }

  ngOnInit(): void {
    this.fetchNotifications();
    this._signalRService.addNotificationListener((notification: Notification) => {
      if (this._authService.userAccount().name == notification.assignedTo) {
        this.fetchNotifications();
      }
    });
  }

  /**
   * Toggle the sidebar menu
   */
  toggleMenu(): void {
    this._menuService.toggleMenu();
  }

  /**
   * Redirects the user to the notification link and marks the notification as read
   *
   * @param notificationId
   */
  async openNotification(notificationId: string): Promise<void> {
    await this._notificationService.markNotificationAsRead(parseInt(notificationId, 10));
    this.fetchNotifications();
  }

  /**
   * Returns the current state of the loader.
   * @returns Loaderbar
   */
  get loaderbarState(): Loaderbar {
    return this._loaderService.getLoaderState();
  }

  /**
   * Method to show the correct color loading bar based on request type.
   *
   * @returns string
   */
  determineLoaderColor(): string {
    // Below methods are getting the colors defined in the 2care4 theme variables.scss file
    switch (this.loaderbarState.requestMethod) {
      case 'GET':
        return 'secondary-color';

      case 'POST':
      case 'PUT':
        return 'primary-color';

      case 'DELETE':
        return 'danger-color';

      default:
        throw new Error(
          'Request method not yet implemented: ' +
          this.loaderbarState.requestMethod
        );
    }
  }

  /**
   * Fetches the notifications for the current user
   *
   * @returns void
   */
  private async fetchNotifications(): Promise<void> {
    const notifications: Notification[] =
      await this._notificationService.getNotificationsByAssignedTo(this._authService.userAccount().name);
    this.notifications.set(notifications);
  }
}
