import {
  Component,
  EventEmitter,
  Input,
  Output,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { EditorModule } from 'primeng/editor';
import { FormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { ServiceRequestService } from '../../service-request.service';
import { InputItem } from '../../../../core/models/input-item';
import { ServiceRequest } from '../../service-request';
import { InputComponent } from '../../../../core/utilities/input/input.component';

@Component({
  selector: 'carehub-service-request-update',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    InputComponent,
    EditorModule,
    FormsModule,
    ConfirmDialogModule,
  ],
  providers: [ConfirmationService],
  templateUrl: './service-request-update.component.html',
  styleUrl: './service-request-update.component.scss',
})
export class ServiceRequestUpdateComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  @Input() serviceRequest!: WritableSignal<ServiceRequest>;

  @Output() serviceRequestChange = new EventEmitter<
    WritableSignal<ServiceRequest>
  >();

  closeReason: string = '';

  options: InputItem[] = [
    { name: 'Low', value: 3 },
    { name: 'Medium', value: 2 },
    { name: 'High', value: 1 },
  ];

  constructor(
    private _confirmationService: ConfirmationService,
    private _serviceRequestService: ServiceRequestService
  ) {}

  close(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  /**
   * Confirm the closing of the service request
   */
  confirmCloseServiceRequest(): void {
    this._confirmationService.confirm({
      header: 'Confirmation',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button',
      acceptButtonStyleClass: 'p-button-outlined p-button',
      accept: () => {
        this.closeServiceRequest();
      },
    });
  }

  /**
   * Close the service request with adding a reason to the description
   */
  async closeServiceRequest(): Promise<void> {
    this.serviceRequest().status = 'Closed';
    this.serviceRequest().description =
      'Service request has been closed by the requester <br>Reason: ' +
      this.closeReason +
      '<br><br> ---------- <br><br>' +
      this.serviceRequest().description;

    this.serviceRequest.set(
      await this._serviceRequestService.update(this.serviceRequest())
    );

    this.serviceRequestChange.emit(this.serviceRequest);
    this.closeReason = '';
    this.close();
  }

  /**
   * Update the service request
   */
  update(): void {
    this._serviceRequestService.update(this.serviceRequest());
  }
}
