import { CommonModule } from '@angular/common';
import { Component, OnDestroy, signal, WritableSignal, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';

import { AuthService } from './core/auth/auth.service';
import { HeaderComponent } from './core/layout/header/header.component';
import { SidebarComponent } from './core/layout/sidebar/sidebar.component';

@Component({
  selector: 'carehub-root',
  standalone: true,
  imports: [
    RouterOutlet,
    SidebarComponent,
    HeaderComponent,
    ToastModule,
    CommonModule,
    CardModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnDestroy, OnInit {
  isLoggedIn: WritableSignal<boolean> = signal(false);

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.initialize();
    this.isLoggedIn = this.authService.isLoggedIn;
  }

  login(): void {
    this.authService.login();
  }

  logout(): void {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authService.dispose();
  }
}
