<div class="flex">
  <p class="mt-3">
    Please fill out the form below to create a service request, this will help
    us to quickly assess and assign the correct person to help you with your
    issue. But before creating the service request, please ask in your
    department if they know a solution, or if a service request has already been
    created.
    <br /><br />
    You can also check if a service request has already been created by looking
    in the list of service requests
    <a [routerLink]="['/utilities/service-requests']" (click)="closeDialog()"
      >here</a
    >.
  </p>
</div>

<hr />

<p-stepper>
  <!-- STEP 1 -->
  <p-stepperPanel header="Base information">
    <ng-template
      pTemplate="content"
      let-nextCallback="nextCallback"
      let-index="index">
      <div class="flex">
        <carehub-input
          inputType="text"
          id="title"
          label="Title"
          [(value)]="serviceRequest().title"
          class="col-7"
          helpText="We need a good descriptive title for the service request, as this will help us quickly to assess and assign the correct person"></carehub-input>
        <carehub-input
          inputType="select"
          id="type"
          label="Type"
          [items]="types"
          [(value)]="serviceRequest().requestType"
          class="col-5"
          helpText="If you can determine the type of issue you have it will also help a great deal to assess and assign the correct person"
          placeholder="Select a type"></carehub-input>
      </div>

      <div class="flex">
        <carehub-input
          inputType="wysiwyg"
          id="description"
          label="Description"
          [(value)]="serviceRequest().description"
          [rows]="12"
          class="col-12"
          helpText="The better you can describe the issue you are having, the better we can help you"></carehub-input>
      </div>

      <div class="flex justify-content-end">
        <p-button label="Next" (onClick)="nextCallback.emit()" />
      </div>
    </ng-template>
  </p-stepperPanel>

  <!-- STEP 2 -->
  <p-stepperPanel header="Extended information">
    <ng-template
      pTemplate="content"
      let-prevCallback="prevCallback"
      let-nextCallback="nextCallback"
      let-index="index">
      <div class="flex">
        <carehub-input
          inputType="calendar"
          id="duedate"
          label="Due date"
          [(value)]="serviceRequest().dueDate"
          class="col-4"
          placeholder="mm/dd/yyyy"
          helpText="Does the service request need to be fixed by a certain date?"></carehub-input>
        <carehub-input
          inputType="switch"
          id="compliance"
          label="Compliance"
          [(value)]="serviceRequest().compliance"
          class="col-2"
          helpText="Does the service request subject and issue have influence on the compliance?"></carehub-input>
        <carehub-input
          inputType="switch"
          id="confidential"
          label="Confidential"
          [(value)]="serviceRequest().confidential"
          class="col-2"
          helpText="Is the service request regarding a subject that should be handled confidentially?"></carehub-input>
        <carehub-input
          inputType="select"
          id="severity"
          label="Severity"
          [(value)]="serviceRequest().severity"
          (valueChange)="updateServiceRequestSeverity($event)"
          [items]="severityOptions"
          class="col-2"
          helpText="What severity does the service request have?"></carehub-input>
        <carehub-input
          inputType="select"
          id="urgency"
          label="Urgency"
          [(value)]="serviceRequest().urgency"
          (valueChange)="updateServiceRequestUrgency($event)"
          [items]="urgencyOptions"
          class="col-2"
          helpText="Is the service request urgent?"></carehub-input>
      </div>

      <hr />

      <div class="flex">
        <p class="col-9">
          If you create a critical service request, we expect you to be ready to
          respond to further questions as soon as possible, as we will start
          working on this immediately, this is also why you need to be very
          respectful about using the critical service request function.
          <br /><br />
          A way to determine if a service request is critical is if the issue is
          stopping you completely from working, or if it is a critical part of
          your work.
        </p>
        <carehub-input
          inputType="switch"
          id="critical"
          label="Critical"
          [(value)]="serviceRequest().critical"
          class="col-3"
          (valueChange)="confirmCriticalServiceRequest()"></carehub-input>
      </div>

      <hr />

      <div class="flex">
        <p class="col-6">
          Based on your choices regarding the due date, compliance, severity and
          urgency and critical fields, a priority will be calculated
          automatically.
        </p>
        <p class="col-6">
          Pre calculated priority: {{ serviceRequestPriority() }}
        </p>
      </div>

      <hr class="mb-5" />

      <div class="flex justify-content-between">
        <p-button
          label="Back"
          severity="secondary"
          (onClick)="prevCallback.emit()" />
        <p-button label="Next" (onClick)="nextCallback.emit()" />
      </div>
    </ng-template>
  </p-stepperPanel>

  <!-- STEP 3 -->
  <p-stepperPanel header="Attachments">
    <ng-template
      pTemplate="content"
      let-prevCallback="prevCallback"
      let-nextCallback="nextCallback"
      let-index="index">
      <div class="flex flex-wrap mb-4">
        <small class="col-12"
          >You can upload images, excel, word files etc. to help us understand
          the issue better</small
        >
        <p-fileUpload
          class="col-12"
          mode="advanced"
          chooseLabel="Choose"
          chooseIcon="pi pi-upload"
          cancelLabel="Cancel"
          showUploadButton="false"
          name="demo[]"
          accept="image/*"
          maxFileSize="1000000"
          multiple="true"
          (onClear)="onClear()"
          (onSelect)="onSelect($event)" />
      </div>

      <div class="flex justify-content-between">
        <p-button
          label="Back"
          severity="secondary"
          (onClick)="prevCallback.emit()" />
        <p-button label="Next" (onClick)="nextCallback.emit()" />
      </div>
    </ng-template>
  </p-stepperPanel>

  <!-- STEP 4 -->
  <p-stepperPanel header="Service request resume">
    <ng-template
      pTemplate="content"
      let-prevCallback="prevCallback"
      let-index="index">
      <div class="flex flex-wrap">
        <div class="col-12">
          <p>Below you can see a resume of the service request:</p>
        </div>
        <div class="col-6">
          <p>Title: {{ serviceRequest().title }}</p>
          <p>
            Description: <br /><span
              [innerHTML]="serviceRequest().description"></span>
          </p>
        </div>
        <div class="col-6">
          <p>Due date: {{ serviceRequest().dueDate }}</p>
          <p>Compliance: {{ serviceRequest().compliance }}</p>
          <p>Confidential: {{ serviceRequest().confidential }}</p>
          <p>Severity: {{ serviceRequest().severity }}</p>
          <p>Urgency: {{ serviceRequest().urgency }}</p>
          <p>Critical: {{ serviceRequest().critical }}</p>
          <p>Amount of attached files: {{ serviceRequest().files?.length }}</p>
          <p>
            Calculated priority:
            {{ serviceRequestPriority() | number: '1.0-0' }}
          </p>
        </div>
      </div>
      <hr />
      <div class="flex">
        <p class="col-9">
          Please confirm that you have checked with your colleagues if they know
          a solution, or if a service request has already been created.
        </p>
        <carehub-input
          inputType="switch"
          id="confirm"
          label="Checked with colleagues"
          [(value)]="confirmedWithColleagues"
          class="col-3"></carehub-input>
      </div>

      <div class="flex justify-content-between">
        <p-button
          label="Back"
          severity="secondary"
          (onClick)="prevCallback.emit()" />
        <p-button
          [severity]="!confirmedWithColleagues ? 'secondary' : 'primary'"
          label="Create service request"
          (click)="createServiceRequest()"
          [disabled]="!confirmedWithColleagues" />
      </div>
    </ng-template>
  </p-stepperPanel>
</p-stepper>

<p-confirmDialog>
  <ng-template pTemplate="message" let-message>
    <div class="p-4">
      <p>
        Are you sure this Service request is critical? <br /><br />
        If so when it is sent, IT will be notified immediately and start working
        on it, <br />
        if not it would be disrespectful to the IT team and their time and other
        tasks.
      </p>
    </div>
  </ng-template>
</p-confirmDialog>
