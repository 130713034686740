import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';

import { AuthService } from '../../../core/auth/auth.service';
import { NotificationSettings } from '../models/notification-settings';
import { SettingsService } from '../services/settings.service';

@Component({
    selector: 'carehub-settings',
    standalone: true,
    imports: [
        ButtonModule,
        CommonModule,
        DividerModule,
        InputSwitchModule,
        ReactiveFormsModule,
    ],
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
    notificationSettingsGroup: FormGroup;
    notificationSettingsId = signal<number>(0);

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private settingsService: SettingsService) {
        this.notificationSettingsGroup = this.fb.group({
            isEmailEnabled: [false, Validators.required],
            isSmsEnabled: [false, [Validators.required]]
        });
    }

    ngOnInit(): void {
        this.fetchSettings();
    }

    save(): void {
        const settings: NotificationSettings = this.getUserInput();
        if (this.notificationSettingsId() !== 0) {
            this.updateSettings(settings);
        } else {
            this.createSettings(settings);
        }
    }

    private async createSettings(settings: NotificationSettings): Promise<void> {
        await this.settingsService.createNotificationSettings(settings);
    }

    private async updateSettings(settings: NotificationSettings): Promise<void> {
        await this.settingsService.updateNotificationSettings(settings);
    }

    private async fetchSettings(): Promise<void> {
        const user: string = this.authService.userAccount().name;
        const settings: NotificationSettings =
            await this.settingsService.getNotificationSettingsByUser(user);

        if (settings != null) {
            this.notificationSettingsGroup.patchValue(settings);
            this.notificationSettingsId.set(settings.id);
        }
    }

    private getUserInput(): NotificationSettings {
        const settings: NotificationSettings =
            this.notificationSettingsGroup.value;
        settings.user = this.authService.userAccount().name;
        settings.id = this.notificationSettingsId();

        return settings;
    }
}
