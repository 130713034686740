<p-dialog
  header="Create request"
  [modal]="true"
  [(visible)]="visible"
  closeOnEscape
  dismissableMask
  (onHide)="close()"
  styleClass="request-dialog">
  <div
    class="flex justify-content-center h-full align-items-center"
    *ngIf="requestType() === ''">
    <p>Select what type of request you would like to make</p>
    <carehub-input
      class="ml-3"
      *ngIf="requestType() === ''"
      inputType="select"
      [items]="requestTypes"
      placeholder="Select a type"
      (valueChange)="selectRequestType($event)">
    </carehub-input>
  </div>
  <carehub-service-request-creation
    *ngIf="requestType() === 'serviceRequest'"
    (closeEvent)="close()"></carehub-service-request-creation>
</p-dialog>
