import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationService } from 'primeng/api';
import { ServiceRequestCreationComponent } from '../service-request-creation/service-request-creation.component';
import { InputItem } from '../../../../core/models/input-item';
import { InputComponent } from '../../../../core/utilities/input/input.component';

type InputTypes =
  | string
  | number
  | boolean
  | InputItem[]
  | InputItem
  | Date
  | null;

@Component({
  selector: 'carehub-request-creation',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    InputComponent,
    ServiceRequestCreationComponent,
  ],
  providers: [ConfirmationService],
  templateUrl: './request-creation.component.html',
  styleUrl: './request-creation.component.scss',
})
export class RequestCreationComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  requestType: WritableSignal<string> = signal('');
  requestTypes: InputItem[] = [
    { name: 'Service Request', value: 'serviceRequest' },
  ];

  /**
   * Select the request type
   *
   * @param requestType
   */
  selectRequestType(requestType: InputTypes): void {
    if (!requestType) {
      return;
    }
    const requestTypeValue: InputItem = requestType as InputItem;
    this.requestType.set(requestTypeValue.value as string);
  }

  /**
   * Close the request creation dialog
   */
  close(): void {
    this.visible = false;
    this.requestType.set('');
    this.visibleChange.emit(this.visible);
  }
}
