import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { RoleGuard } from './core/auth/role.guard';
import { GuardedComponent } from './features/guarded/pages/guarded/guarded.component';
import { ServiceRequestsComponent } from './features/requests/components/service-requests/service-requests.component';
import { serviceRequestsResolver } from './features/requests/service-requests.resolver';
import { SettingsComponent } from './features/settings/pages/settings.component';

export const routes: Routes = [
  {
    path: '',
    canActivateChild: [MsalGuard],
    // All routes that should be protected by MSAL guard should be children of this route
    children: [
      {
        path: 'guarded',
        component: GuardedComponent,
        data: {
          roles: ['CareHub.Admin'],
        },
        canActivate: [RoleGuard],
      },
      {
        path: 'utilities',
        title: 'Utilities',
        children: [
          {
            path: 'service-requests',
            title: 'Service requests',
            component: ServiceRequestsComponent,
            resolve: {
              serviceRequests: serviceRequestsResolver,
            },
          },
        ],
      },
      {
        path: 'settings',
        component: SettingsComponent,
      }
    ],
  },
  /** Route that catches nonexisting routes and redirects them to home */
  {
    path: '**',
    redirectTo: '',
  },
];
