<div class="flex flex-column gap-2">
  <!-- LABEL -->
  <label *ngIf="label" [for]="id">{{ label }}</label>

  <!-- TEXT INPUT -->
  <input
    *ngIf="inputType === 'text'"
    pInputText
    [id]="id"
    [(ngModel)]="value"
    (keyup)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [placeholder]="placeholder"
    [disabled]="disabled"
    autocomplete="off" />

  <!-- NUMBER INPUT -->
  <p-inputNumber
    *ngIf="inputType === 'number'"
    [id]="id"
    [(ngModel)]="value"
    (keyup)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [useGrouping]="false"
    [placeholder]="placeholder"
    [disabled]="disabled" />

  <!-- SWITCH INPUT -->
  <p-inputSwitch
    *ngIf="inputType === 'switch'"
    [id]="id"
    [(ngModel)]="value"
    (click)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [disabled]="disabled" />

  <!-- TEXTAREA INPUT -->
  <textarea
    *ngIf="inputType === 'textarea'"
    [rows]="rows"
    [cols]="cols"
    [id]="id"
    pInputTextarea
    [(ngModel)]="value"
    (keyup)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [placeholder]="placeholder"
    [disabled]="disabled">
  </textarea>

  <p-editor
    *ngIf="inputType === 'wysiwyg'"
    [id]="id"
    pInputTextarea
    [(ngModel)]="value"
    (keyup)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [disabled]="disabled" />

  <!-- RADIO INPUT -->
  <ng-template *ngIf="inputType === 'radio' && items">
    <div *ngFor="let item of items">
      <p-radioButton
        [value]="item.value"
        [id]="id"
        [(ngModel)]="value"
        (click)="emitValue()"
        [pTooltip]="tooltip"
        tooltipPosition="top"
        [disabled]="disabled">
        {{ item.name }}
      </p-radioButton>
    </div>
  </ng-template>

  <!-- MULTISELECT INPUT -->
  <p-multiSelect
    *ngIf="inputType === 'multiselect' && items"
    [options]="items"
    [(ngModel)]="value"
    [id]="id"
    (onChange)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    optionLabel="name"
    [disabled]="disabled"
    [placeholder]="placeholder" />

  <!-- SELECT INPUT -->
  <p-dropdown
    *ngIf="inputType === 'select' && items"
    [options]="items"
    [(ngModel)]="value"
    [id]="id"
    (onChange)="emitValue()"
    optionLabel="name"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    optionLabel="name"
    [disabled]="disabled"
    [placeholder]="placeholder" />

  <!-- AUTOCOMPLETE INPUT -->
  <p-autoComplete
    *ngIf="inputType === 'autocomplete' && items"
    [(ngModel)]="value"
    [suggestions]="items"
    (completeMethod)="search($event)"
    field="name"
    [id]="id"
    (onSelect)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [disabled]="disabled"
    [placeholder]="placeholder" />

  <!-- CALENDAR INPUT -->
  <p-calendar
    *ngIf="inputType === 'calendar'"
    [(ngModel)]="value"
    [id]="id"
    (onSelect)="emitValue()"
    [pTooltip]="tooltip"
    tooltipPosition="top"
    [disabled]="disabled"
    [placeholder]="placeholder" />

  <small *ngIf="helpText">{{ helpText }}</small>
  <br />
  <small *ngIf="explanation">{{ explanation() }}</small>
</div>
